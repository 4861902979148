<template>
  <div class="pointes-list">
    <div class="records-list">
      <div class="record" v-for="item in items" :key="item.id">
        <div>
          <div class="record__title">{{ item.name }}</div>
          <div class="record__desc">
            <div class="record__date">交易日期 {{ item.date }}</div>
            <div class="record__date" v-if="item.end_date">到期日 {{ item.end_date }}</div>
          </div>
        </div>
        <div class="s-text-primary record__point">
          <span v-if="item.is_redeem"> - </span>
          {{ item.point }}
        </div>
      </div>
    </div>
    <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
  </div>
</template>

<script>
import PointPagination from "@/pages/Liff/Point/PointPagination";
import moment from "moment";
import voucherApi from '@/apis/liff/v2/voucher'

export default {
  components: {
    PointPagination,
  },
  data() {
    return {
      items: [
      ],
      page: 1,
      totalPage: 1,
    };
  },
  watch: {
    page() {
      this.fetchPointLogs()
    }
  },
  mounted() {
    this.fetchPointLogs()
  },
  methods: {
    async fetchPointLogs() {
      try {
        let res = await voucherApi.listVouchers(this.page);
        let items = res.data;
        this.totalPage = res.pagination.total_pages;

        if (!items || !items.length) {
          this.items = [];
          return;
        }

        this.items = items
          .map((pl) => ({
            id: pl.id,
            name: pl.remarks,
            point: pl.value,
            date: moment(pl.created_at).format("YYYY/MM/DD"),
            end_date: pl.end_at ? moment(pl.end_at).format("YYYY/MM/DD") : false,
            is_redeem: pl.is_redeem,
          }))
      } catch (error) {
        this.$swal("Error", error.response.data.message, "error");
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.records-list {
  padding: 0 12px;
  background: #fff;
}
.record {
  border-bottom: 1px solid #e5e5ea;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    div {
      margin-bottom: 8px;
    }
  }

  &__point {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
