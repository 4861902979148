<template>
  <div class="pointes-list">
    <div class="records-list">
      <div class="record" v-for="item in pointLogs" :key="item.id">
        <div>
          <div class="record__title">{{ item.name }}</div>
          <div class="record__desc">
            <div class="record__date">交易日期 {{ item.date }}</div>
            <div class="record__date">紅利點數效期 {{ item.date }}</div>
            <div class="record__date">消費交易</div>
          </div>
        </div>
        <div class="s-text-primary record__point">{{ item.point }}</div>
      </div>
    </div>
    <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
  </div>
</template>

<script>
import PointPagination from "@/pages/Liff/Point/PointPagination";
import moment from "moment";
import pointApi from '@/apis/liff/v2/point'

export default {
  components: {
    PointPagination,
  },
  data() {
    return {
      pointLogs: [
        /* {
         *   id: 1,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 2,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 3,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 4,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * },
         * {
         *   id: 5,
         *   name: "購物贈點",
         *   date: "2021/10/05",
         *   point: 182,
         * }, */
      ],
      page: 1,
      totalPage: 1,
      eventCode: this.$route.query.event ?? null,
    };
  },
  watch: {
    page() {
      this.fetchPointLogs()
    }
  },
  mounted() {
    this.fetchPointLogs()
  },
  methods: {
    async fetchPointLogs() {
      let res = await pointApi.listPointsLogs(this.eventCode, this.page);
      let point_logs = res.data;
      this.totalPage = res.meta.last_page;

      if (!point_logs || !point_logs.length) {
        this.pointLogs = [];
        return;
      }

      this.pointLogs = point_logs
        .map((pl) => ({
          id: pl.id,
          name: "購物積點",
          point: pl.batch_total_points,
          date: moment(pl.created_at).format("YYYY/MM/DD"),
          status: "finish",
        }))

      if (res.links.next === null) {
        this.noMore = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.records-list {
  padding: 0 12px;
  background: #fff;
}
.record {
  border-bottom: 1px solid #e5e5ea;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    div {
      margin-bottom: 8px;
    }
  }

  &__point {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
