<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="content">
        <Header
          :meta="meta"
          :page-title="moduleConfig.page_title"
          :page-image="moduleConfig.page_image"
          :header-section="moduleConfig.header_section"
        />
        <div class="d-flex flex-column view">
          <div class="banner text-center" v-if="headerImage">
            <img :src="headerImage" />
          </div>
          <div
            class="d-flex flex-column p-3 menu flex-fill"
            :style="{ 'background-image': 'url(' + contentImage + ')' }"
          >
            <div class="px-5 mb-3">
              <b-card class="points text-center">
                <span class="points__title">累積點數</span>
                <div class="points__number">{{ formattedAvailablePoints }}</div>
              </b-card>
            </div>

            <router-link
              v-for="i in options"
              :key="i.text"
              class="menu__item mb-2"
              :to="{
                name: i.value,
                params: { org_code: $route.params.org_code },
              }"
            >
              <span class="menu__title">{{ i.text }}</span>
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2773 9.85059C13.2773 9.65723 13.1982 9.48144 13.0576 9.34961L6.44824 3.00391C6.32519 2.88086 6.17578 2.81055 5.99121 2.81055C5.62207 2.81055 5.34082 3.0918 5.34082 3.45215C5.34082 3.62793 5.41113 3.79492 5.52539 3.91797L11.6953 9.8418L5.52539 15.7744C5.41113 15.8975 5.34082 16.0557 5.34082 16.2314C5.34082 16.6006 5.62207 16.8818 5.99121 16.8818C6.17578 16.8818 6.3252 16.8027 6.44824 16.6885L13.0576 10.334C13.1982 10.1934 13.2773 10.0352 13.2773 9.85059Z"
                  fill="white"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV2";
import { mapGetters, mapActions } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  components: { Header },
  data() {
    return {
      options: [
        { text: "贈品兌換", value: "LiffPointsExchange" },
        { text: "兌換紀錄", value: "LiffPointsExchangeRecords" },
        { text: "入點紀錄", value: "LiffPointsRechargeRecords" },
        { text: "兌獎資訊填寫", value: "LiffMemberCenterEdit" },
      ],
      themeConfigPage: "point_center",
    };
  },
  mounted() {
    this.fetchAvailablePoints({ eventCode: null });
  },
  methods: {
    ...mapActions("liffPoint", [
      "fetchAvailablePoints",
      "fetchExpiringSoonPoints",
    ]),
  },
  computed: {
    ...mapGetters("liffPoint", [
      "availablePoints",
      "expiringSoonPoints",
      "expiringSoonPointsDate",
    ]),
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    formattedAvailablePoints() {
      return this.availablePoints?.toLocaleString("en-US");
    },
    headerImage() {
      return this.moduleConfig.header_bg_image ?? "";
    },
    contentImage() {
      return this.moduleConfig.content_bg_image ?? "";
    },
    moduleConfig() {
      return this.getModule("point_center").module_config || {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
}

.content {
 
  background: var(--liff-content_bg_color);
}

.view {
  min-height: 100vh;

  .banner {
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .menu {
    background-size: cover;
    background-position-x: center;

    .points {
      border-radius: 1rem;
      margin-top: -3rem;

      &__title {
        color: var(--liff-secondary_text_color);
      }
      &__number {
        font-size: 40px;
        font-weight: 800;
        color: var(--s-primary);
      }
    }

    &__item {
      &::before {
        content: "";
      }
      padding: 0.8rem;
      display: flex;
      justify-content: space-between;

      border-radius: 10px;
      background-color: var(--liff-button-color);
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: var(--liff-button-text-color);
    }
  }
}
</style>
